

















import Vue from 'vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'

export default Vue.extend({
  name: 'Enable2FA',
  components: { SubmitButton },
  data() {
    return {
      code: '',
    }
  },
  methods: {
    verify(): void {
      // TODO: Implement API call
    },
  },
})
